import {
  AspectRatio,
  FormatIndentDecrease,
  FormatIndentIncrease,
  // LiveHelp,
  Lock,
  LockOpen,
  // NotificationsActiveOutlined,
  // NotificationsNone,
} from '@mui/icons-material';
import { AppBar, Avatar, Box, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material';
import classNames from 'classnames';
import React, { Component } from 'react';
import shortid from 'shortid';

import dispatchers from './dispatchers';
// import Notification from './Notification';
import UniversalSearch from './UniversalSearch';
import connect from '../../../lib/connect';
// import CustomBadge from '../../partial/CustomBadge';

interface Props extends GlobalProps {
  openPopup: string;
  togglePopup: any;
  toggleLeftDrawer: any;
  toggleRightDrawer: any;
}

// @ts-ignore
if (process.browser && window) {
  // @ts-ignore
  window.Tawk_API = window.Tawk_API || {};
}

// @ts-ignore
// const tawkClient = process.browser && window?.Tawk_API;
// const isDev = process.env.ENV !== 'production';

const styles = (theme: any): any => ({
  appBar: {
    // position: 'absolute',
    zIndex: theme.zIndex.drawer + 10,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolBar: { padding: theme.spacing(0, 1.5, 0, 0) },
  leftDrawerBtnMobile: {
    transform: 'rotate(180deg)',
    color: theme.palette.common.white,
  },
  leftDrawerBtn: {
    color: theme.palette.common.white,
  },
  rightDrawerBtn: { color: theme.palette.common.white },
  flex: {
    color: theme.palette.common.white,
    flex: 1,
  },
  menuBtn: {
    // height: 64,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
  },
  menuItem: {
    width: 345,
    height: 'auto',
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    borderBottom: '1px solid #80808080',
    '&:last-child': { borderBottom: 'none' },
  },
});

class Header extends Component<Props> {
  componentDidMount = () => {
    this.initListeners();
  };

  componentWillUnmount = () => {
    this.removeListeners();
  };

  isFullScreenAvailable = (): boolean => {
    if (
      ((document as any).fullScreenElement && (document as any).fullScreenElement !== null) ||
      (!(document as any).mozFullScreen && !(document as any).webkitIsFullScreen)
    ) {
      if ((document as any).documentElement.requestFullScreen) {
        return true;
      }
      if ((document as any).documentElement.mozRequestFullScreen) {
        return true;
      }
      if ((document as any).documentElement.webkitRequestFullScreen) {
        return true;
      }
    } else if ((document as any).cancelFullScreen) {
      return true;
    } else if ((document as any).mozCancelFullScreen) {
      return true;
    } else if ((document as any).webkitCancelFullScreen) {
      return true;
    }

    return false;
  };

  fullScreen = (): void => {
    if (
      ((document as any).fullScreenElement && (document as any).fullScreenElement !== null) ||
      (!(document as any).mozFullScreen && !(document as any).webkitIsFullScreen)
    ) {
      if ((document as any).documentElement.requestFullScreen) {
        (document as any).documentElement.requestFullScreen();
      } else if ((document as any).documentElement.mozRequestFullScreen) {
        (document as any).documentElement.mozRequestFullScreen();
      } else if ((document as any).documentElement.webkitRequestFullScreen) {
        (document as any).documentElement.webkitRequestFullScreen((Element as any).ALLOW_KEYBOARD_INPUT);
      }
    } else if ((document as any).cancelFullScreen) {
      (document as any).cancelFullScreen();
    } else if ((document as any).mozCancelFullScreen) {
      (document as any).mozCancelFullScreen();
    } else if ((document as any).webkitCancelFullScreen) {
      (document as any).webkitCancelFullScreen();
    }
  };

  togglePopup = (name = ''): any => {
    const {
      redux: { state },
    } = this.props;

    if (!state.openPopup) {
      return (e: any) => this.props.togglePopup({ anchorEl: e.currentTarget, openPopup: name });
    }
    return () => this.props.togglePopup({ anchorEl: null, openPopup: '' });
  };

  logout = (): void => {
    return this.props
      .logout()
      .then(() => (location.href = '/login'))
      .catch(this.props.logger.error);
  };

  notificationsListener = async data => {
    data.notificationType = data.ticketId ? 'ticket' : data.notificationType;

    if (Array.isArray(data.headers) && data.headers[0]) {
      data.headers = data.headers.reduce((a, c) => {
        a[c.name] = c.value;

        return a;
      }, {});

      data.from = data.headers.from;
      data.label = data.headers.subject || data.snippet;
    }

    if (data.receivedAt) {
      data.timestamp = data.receivedAt;
    }

    await Promise.all([
      this.props.asyncInitState('badges'),
      this.props.addNotification(data),
      this.props.toggleSnack(
        `You have new ${!data.notificationType ? 'notification' : data.notificationType} from ${data.headers && data.headers.from && data.headers.from.length > 125 ? `${data.headers.from.substr(0, 125)}...` : data.headers.from}.`
      ),
    ]).catch(this.props.logger.error);
  };

  initListeners = () => {
    const { socket } = this.props;

    socket.service('v1/objects/ticket-messages').removeListener('created', this.notificationsListener);
    socket.service('v1/objects/ticket-messages').on('created', this.notificationsListener);
  };

  removeListeners = () => {
    const { socket } = this.props;

    socket.service('v1/objects/ticket-messages').removeListener('created', this.notificationsListener);
  };

  render() {
    const {
      classes,
      redux: { state },
    } = this.props;
    const newCallsCount = state.eventsHistory.filter(c => c.status !== 'completed').length;
    const isMobile = this.props.isMobile();

    return (
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar variant="dense" className={classes.toolBar}>
          {/* <Phone /> */}
          {isMobile ? (
            <IconButton onClick={this.props.toggleLeftDrawer} size="small">
              {state.leftDrawer ? (
                <FormatIndentIncrease className={classes.leftDrawerBtnMobile} />
              ) : (
                <FormatIndentDecrease className={classes.leftDrawerBtnMobile} />
              )}
            </IconButton>
          ) : (
            <IconButton onClick={this.props.toggleLeftDrawer} size="small">
              {state.leftDrawer ? (
                <Lock className={classes.leftDrawerBtn} />
              ) : (
                <LockOpen className={classes.leftDrawerBtn} />
              )}
            </IconButton>
          )}
          {!isMobile && (
            <Typography
              variant="h5"
              className={classes.flex}
              onClick={() => this.props.router.push('/')}
              style={{ cursor: 'pointer' }}
            >
              {state.user.tenant.name || state.user.tenant.key}
            </Typography>
          )}

          {isMobile ? (
            <Box sx={{ flex: 1 }}>
              <UniversalSearch />
            </Box>
          ) : (
            <UniversalSearch />
          )}

          {/* {tawkClient?.maximize && (
            <IconButton className={classes.menuBtn} onClick={() => tawkClient?.maximize()} size="small">
              <CustomBadge badgeContent={state.notifications.length}>
                <LiveHelp />
              </CustomBadge>
            </IconButton>
          )} */}
          {!isMobile && this.isFullScreenAvailable() && (
            <IconButton className={classes.menuBtn} onClick={this.fullScreen} size="small">
              <Tooltip id={shortid.generate()} title="full screen">
                <AspectRatio />
              </Tooltip>
            </IconButton>
          )}
          {/* {!isMobile && (
            <IconButton
              className={classes.menuBtn}
              aria-owns={state.anchorEl ? 'notification' : undefined}
              disabled={!state.notifications[0]}
              onClick={this.togglePopup('notification')}
            >
              <CustomBadge badgeContent={state.notifications.length}>
                {!state.notifications.length ? <NotificationsNone /> : <NotificationsActiveOutlined />}
              </CustomBadge>
            </IconButton>
          )} */}
          {/* <Menu
            id="notification"
            anchorEl={state.anchorEl}
            open={state.openPopup === 'notification'}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            MenuListProps={{ className: classes.noPadding }}
            onClose={this.togglePopup()}
          >
            {state.notifications.reverse().map(msg => (
              <MenuItem className={classes.menuItem} key={msg.timestamp}>
                <Notification message={msg} />
              </MenuItem>
            ))}
          </Menu> */}
          <IconButton
            className={classNames(classes.menuBtn, classes.noPadding)}
            aria-owns={state.anchorEl ? 'profile' : undefined}
            onClick={this.togglePopup('profile')}
            size="small"
          >
            <Tooltip id={shortid.generate()} title="my account">
              <Avatar src={state.user.avatar?.url} />
            </Tooltip>
          </IconButton>
          <Menu
            id="profile"
            anchorEl={state.anchorEl}
            open={state.openPopup === 'profile'}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={this.togglePopup()}
          >
            <MenuItem
              onClick={() => {
                this.togglePopup()();
                this.props.router.push('/settings/profile');
              }}
            >
              Profile
            </MenuItem>
            <MenuItem onClick={this.logout}>Logout</MenuItem>
          </Menu>
          {/* <IconButton className={classes.menuBtn} onClick={this.props.toggleRightDrawer}>
            {newCallsCount ? (
              <CustomBadge color="secondary" badgeContent={newCallsCount}>
                {state.rightDrawer ? (
                  <FormatIndentIncrease className={classes.rightDrawerBtn} />
                ) : (
                  <FormatIndentDecrease className={classes.rightDrawerBtn} />
                )}
              </CustomBadge>
            ) : state.rightDrawer ? (
              <FormatIndentIncrease className={classes.rightDrawerBtn} />
            ) : (
              <FormatIndentDecrease className={classes.rightDrawerBtn} />
            )}
          </IconButton> */}
        </Toolbar>
      </AppBar>
    );
  }
}

export default connect({ styles, dispatchers })(Header);

// clientId: undefined
// connectionId: undefined
// connectionKey: undefined
// data: "{"ticketId":"5c8032c0d7b1c9001097ef7a","emailAddress":"sarkis.tlt@gmail.com","headers":{"return-path":"<sarkis.tlt@gmail.com>","received":"from [10.0.1.29] ([38.142.26.98])        by smtp.gmail.com with ESMTPSA id m20sm8569317pfj.142.2019.03.06.12.51.07        for <sarkis.tlt@gmail.com>        (version=TLS1_2 cipher=ECDHE-RSA-AES128-GCM-SHA256 bits=128/128);        Wed, 06 Mar 2019 12:51:08 -0800 (PST)","date":"Wed, 6 Mar 2019 12:51:01 -0800","from":"Sarkis Arutiunian <sarkis.tlt@gmail.com>","to":"Sarkis Arutiunian <sarkis.tlt@gmail.com>","message-id":"<fa1d330f-03df-40c0-9a39-6c16fbcee038@Spark>","subject":"s","x-readdle-message-id":"fa1d330f-03df-40c0-9a39-6c16fbcee038@Spark","mime-version":"1.0","content-type":"multipart/alternative; boundary=\"5c8032ba_6b8b4567_606e\""},"snippet":"b ______________________________________ Best Regards, Sarkis Arutiunian ……. phone \\ 1 (818) 747 4477 skype \\ sarkis-tlt linkedin \\ https://www.linkedin.com/in/sarkisarutiunian","threadId":"16954c62e6bae6d0","timestamp":1551905472336,"notificationType":"new-ticket","messageId":"16954c62e6bae6d0"}"
// encoding: undefined
// extras: undefined
// id: "Pf5TQdfW-w:0:0"
// name: "notifications"
// timestamp: 1551905472819
