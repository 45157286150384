import {
  ArrowDownward as ArrowDownIcon,
  ArrowUpward as ArrowUpIcon,
  Close as CloseIcon,
  Person as CustomerIcon,
  ExpandLess,
  FilterAlt as FilterIcon,
  ShoppingBag as InboundPOIcon,
  Receipt as InvoiceIcon,
  ArrowForward as NavigateIcon,
  ShoppingCart as OrderIcon,
  LocalShipping as OutboundPOIcon,
  Inventory as ProductIcon,
  Search as SearchIcon,
  LocalShipping as ShipmentIcon,
  CompareArrows as TransferIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useRef, useState } from 'react';

import {
  ChipsContainer,
  DateRangeContainer,
  FilterButtonContainer,
  FilterControlContainer,
  FilterPopover,
  resultItemStyles,
  ResultsContainer,
  SearchContainer,
  SearchField,
  SectionTitle,
  seeAllItemStyles,
  StyledListItem,
} from './UniversalSearchStyles';
import connect from '../../../lib/connect';
import CustomSelect from '../../partial/CustomSelect';

// Define a type for our search results
type SearchResults = {
  customers?: {
    channelName?: string;
    id?: string;
    name?: string;
    email?: string;
    phone?: string;
  }[];
  orders?: {
    channelName?: string;
    id?: string;
    number?: string;
    purchaseOrderNumber?: string;
    customer?: string;
    status?: string;
    total?: string;
    currency?: string;
  }[];
  invoices?: {
    channelName?: string;
    id?: string;
    number?: string;
    purchaseOrderNumber?: string;
    customer?: string;
    amount?: string;
    status?: string;
    currency?: string;
  }[];
  products?: {
    id?: string;
    sku?: string;
    name?: string;
    unitCost?: string;
    currency?: string;
  }[];
  inboundPOs?: {
    channelName?: string;
    id?: string;
    number?: string;
    customer?: string;
    status?: string;
    total?: string;
    currency?: string;
  }[];
  outboundPOs?: {
    id?: string;
    number?: string;
    vendor?: string;
    status?: string;
    total?: string;
    currency?: string;
  }[];
  transfers?: {
    id?: string;
    number?: string;
    fromWarehouse?: string;
    toWarehouse?: string;
    status?: string;
    items?: number;
  }[];
  shipments?: {
    channelName?: string;
    id?: string;
    trackingNumber?: string;
    carrier?: string;
    status?: string;
    destination?: string;
    date?: string;
    deliveredAt?: string;
    customer?: string;
  }[];
};

// Define sort options
type SortOption = {
  field: 'created' | 'updated' | 'name';
  direction: 'asc' | 'desc';
};

// Component with appropriate props type
const ResultItem = (props: React.ComponentProps<typeof StyledListItem>) => (
  <StyledListItem {...props} sx={resultItemStyles} />
);

const SeeAllItem = (props: React.ComponentProps<typeof StyledListItem>) => (
  <StyledListItem {...props} sx={seeAllItemStyles} />
);

interface Props extends GlobalProps {
  setChannel?: (channelIds: string[] | null) => void;
  setSortOption?: (option: SortOption | null) => void;
  setDateRange?: (range: { from: Date | null; to: Date | null } | null) => void;
}

const UniversalSearch = (props: Props) => {
  const { router, api, isLoading, numberToCurrencyStr } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedChannels, setSelectedChannels] = useState<{ value: string; label: string }[]>([]);
  const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLElement | null>(null);
  const [sortField, setSortField] = useState<'created' | 'updated' | 'name'>('created');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [expandedTypes, setExpandedTypes] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<SearchResults>({
    customers: [],
    orders: [],
    invoices: [],
    products: [],
    inboundPOs: [],
    outboundPOs: [],
    transfers: [],
    shipments: [],
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Filter mock data based on search term
  useEffect(() => {
    if (searchTerm.length < 2) {
      setSearchResults({
        customers: [],
        orders: [],
        invoices: [],
        products: [],
        inboundPOs: [],
        outboundPOs: [],
        transfers: [],
        shipments: [],
      });
      setIsOpen(false);
      return;
    }

    // Open results panel when search term meets minimum length
    setIsOpen(true);

    // Toggle loading state
    isLoading(true, 'globalSearch');

    // Create the fetch function inside useEffect
    const fetchSearchResults = async () => {
      try {
        // Prepare API request
        const apiRequest = {
          searchTerm,
          collections: ['all'], // or specific collections if needed
          sortField,
          sortDirection,
          startDate: startDate ? startDate.toISOString() : undefined,
          endDate: endDate ? endDate.toISOString() : undefined,
          channels: selectedChannels.length > 0 ? selectedChannels.map(channel => channel.value) : undefined,
          limit: 10,
        };

        // Call the API using api
        const data = await api.service('v1/admin/global-search').create(apiRequest);
        setSearchResults(data);
      } catch (error) {
        console.error('Error performing search:', error);
        // Fallback to empty results
        setSearchResults({
          customers: [],
          orders: [],
          invoices: [],
          products: [],
          inboundPOs: [],
          outboundPOs: [],
          transfers: [],
          shipments: [],
        });
      } finally {
        // Toggle loading state off
        isLoading(false, 'globalSearch');
      }
    };

    // Debounce the API call without using async directly in setTimeout
    const timeoutId = setTimeout(() => {
      fetchSearchResults();
    }, 300); // 300ms debounce

    // Cleanup function to cancel the timeout if the component unmounts or the dependencies change
    return () => clearTimeout(timeoutId);
  }, [searchTerm, isLoading, api, selectedChannels, sortField, sortDirection, startDate, endDate]);

  // Handle channel selection change
  useEffect(() => {
    if (props.setChannel) {
      props.setChannel(selectedChannels.length > 0 ? selectedChannels.map(channel => channel.value) : null);
    }
  }, [selectedChannels, props.setChannel]);

  // Handle sort option change
  useEffect(() => {
    if (props.setSortOption) {
      props.setSortOption({ field: sortField, direction: sortDirection });
    }
  }, [sortField, sortDirection, props.setSortOption]);

  // Handle date range change
  useEffect(() => {
    if (props.setDateRange) {
      if (startDate || endDate) {
        props.setDateRange({ from: startDate, to: endDate });
      } else {
        props.setDateRange(null);
      }
    }
  }, [startDate, endDate, props.setDateRange]);

  // Helper to determine if there are any results
  const hasResults = Object.values(searchResults).some(results => results.length > 0);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleFocus = () => {
    if (searchTerm.length >= 2) {
      setIsOpen(true);
    }
  };

  const handleClickAway = () => {
    // Only close the results dropdown, not the search field itself
    setIsOpen(false);
  };

  const handleClear = () => {
    setSearchTerm('');
    setIsOpen(false);
  };

  const handleOpenFilterPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleCloseFilterPopover = () => {
    setFilterAnchorEl(null);
  };

  const handleChannelChange = (options: { value: string; label: string }[]) => {
    // Handle different option formats that might be passed
    if (Array.isArray(options)) {
      // Regular array of options
      setSelectedChannels(options);
    } else if (options && typeof options === 'object') {
      // Single option object
      setSelectedChannels([options]);
    } else {
      // Fallback to empty array if options is undefined or not in expected format
      setSelectedChannels([]);
    }
  };

  const handleSortFieldChange = (event: SelectChangeEvent<'created' | 'updated' | 'name'>) => {
    setSortField(event.target.value as 'created' | 'updated' | 'name');
  };

  const handleSortDirectionChange = () => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  const handleClearFilters = () => {
    setSelectedChannels([]);
    setSortField('created');
    setSortDirection('desc');
    setStartDate(null);
    setEndDate(null);
    handleCloseFilterPopover();
  };

  const handleApplyFilters = () => {
    handleCloseFilterPopover();
  };

  const handleItemClick = (item: any, type: string) => {
    console.log(`Clicked on ${type} item:`, item);
    setIsOpen(false);
    setSearchTerm('');

    // Create URLs with timestamp to force refresh
    const timestamp = Date.now();

    // Navigate to the appropriate page based on item type
    switch (type) {
      case 'customers':
        window.location.href = `/order-management/customers/view?customerId=${item.id}`;
        break;
      case 'orders':
        window.location.href = `/order-management/orders/view?orderId=${item.id}`;
        break;
      case 'invoices':
        window.location.href = `/order-management/invoices/view?invoiceId=${item.id}`;
        break;
      case 'products':
        window.location.href = `/supply-chain/products/view?productId=${item.id}`;
        break;
      case 'inboundPOs':
        window.location.href = `/order-management/incoming-pos/view?poId=${item.id}`;
        break;
      case 'outboundPOs':
        window.location.href = `/supply-chain/outgoing-pos/view?poId=${item.id}`;
        break;
      case 'transfers':
        window.location.href = `/warehouse-management/transfers/view?orderId=${item.id}`;
        break;
      case 'shipments':
        window.location.href = `/order-management/shipments?shipmentId=${item.id}`;
        break;
      default:
        console.warn(`No route defined for item type: ${type}`);
    }
  };

  const handleSeeAllClick = (type: string) => {
    console.log(`See all ${type}`);

    // Toggle expanded state for this type
    setExpandedTypes(prev => {
      if (prev.includes(type)) {
        return prev.filter(t => t !== type);
      } else {
        return [...prev, type];
      }
    });

    // Keep search results open
    setIsOpen(true);
  };

  // Helper to get icon for result type
  const getIconForType = (type: string) => {
    switch (type) {
      case 'customers':
        return <CustomerIcon />;
      case 'orders':
        return <OrderIcon />;
      case 'invoices':
        return <InvoiceIcon />;
      case 'products':
        return <ProductIcon />;
      case 'inboundPOs':
        return <InboundPOIcon />;
      case 'outboundPOs':
        return <OutboundPOIcon />;
      case 'transfers':
        return <TransferIcon />;
      case 'shipments':
        return <ShipmentIcon />;
      default:
        return <SearchIcon />;
    }
  };

  // Helper to get display text for result types
  const getDisplayName = (type: string): string => {
    const names: { [key: string]: string } = {
      customers: 'Customers',
      orders: 'Orders',
      invoices: 'Invoices',
      products: 'Products',
      inboundPOs: 'Inbound POs',
      outboundPOs: 'Outbound POs',
      transfers: 'Transfers',
      shipments: 'Shipments',
    };
    return names[type] || type;
  };

  // Helper function to generate consistent color based on channel name
  const getChannelColor = (channelName: string) => {
    // Simple hash function to generate a consistent hue (0-360) from a string
    let hash = 0;
    for (let i = 0; i < channelName.length; i++) {
      hash = channelName.charCodeAt(i) + ((hash << 5) - hash);
    }
    const hue = hash % 360;
    return `hsl(${hue}, 70%, 65%)`;
  };

  // Helper function to render channel name as a label
  const renderChannelLabel = (channelName: string) => {
    if (!channelName) return null;

    const bgColor = getChannelColor(channelName);

    return (
      <Box
        component="span"
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '1px 6px',
          borderRadius: '4px',
          backgroundColor: bgColor,
          color: '#000',
          fontSize: '0.65rem',
          fontWeight: 600,
          marginRight: '4px',
          whiteSpace: 'nowrap',
          opacity: 0.9,
        }}
      >
        {channelName}
      </Box>
    );
  };

  // Helper to get primary and secondary text for different result types
  const getResultTexts = (item: any, type: string) => {
    console.log('item', item);
    switch (type) {
      case 'customers':
        return {
          primary: item.name,
          secondary: (
            <>
              {item.channelName && renderChannelLabel(item.channelName)}
              {item.email || ''}
              {item.phone ? `${item.email && item.phone ? ' • ' : ''}${item.phone}` : ''}
            </>
          ),
          status: null,
        };
      case 'orders':
        return {
          primary: `${item.customer ? `${item.customer} • ` : ''}${item.number}${item.purchaseOrderNumber ? ` • PO# ${item.purchaseOrderNumber}` : ''}`,
          secondary: (
            <>
              {item.channelName && renderChannelLabel(item.channelName)}
              Total: {numberToCurrencyStr(item?.currency, (item?.total / 100).toFixed(2))}
            </>
          ),
          status: item.status,
        };
      case 'invoices':
        return {
          primary: `${item.customer ? `${item.customer} • ` : ''}${item.number}${item.purchaseOrderNumber ? ` • PO# ${item.purchaseOrderNumber}` : ''}`,
          secondary: (
            <>
              {item.channelName && renderChannelLabel(item.channelName)}
              Amount: {numberToCurrencyStr(item?.currency, (item?.amount / 100).toFixed(2))}
            </>
          ),
          status: item.status,
        };
      case 'products':
        return {
          primary: item.name,
          secondary: `${item.sku} • Unit Cost: ${numberToCurrencyStr(item?.currency, (item?.unitCost / 100).toFixed(2))}`,
          status: null,
        };
      case 'inboundPOs':
        return {
          primary: `${item.customer ? `${item.customer} • ` : ''}${item.number}`,
          secondary: (
            <>
              {item.channelName && renderChannelLabel(item.channelName)}
              Total: {numberToCurrencyStr(item?.currency, (item?.total / 100).toFixed(2))}
            </>
          ),
          status: item.status,
        };
      case 'outboundPOs':
        return {
          primary: `${item.vendor ? `${item.vendor} • ` : ''}${item.number}`,
          secondary: `Total: ${numberToCurrencyStr(item?.currency, (item?.total / 100).toFixed(2))}`,
          status: item.status,
        };
      case 'transfers':
        return {
          primary: `${item.number}`,
          secondary: `${item.fromWarehouse} → ${item.toWarehouse} • ${item.items} items`,
          status: item.status,
        };
      case 'shipments':
        return {
          primary: `${item.customer ? `${item.customer} • ` : ''}${item.trackingNumber} • ${item.carrier}`,
          secondary: (
            <>
              {item.channelName && renderChannelLabel(item.channelName)}
              {item.destination} • {item.date}
            </>
          ),
          status: item.status,
        };
      default:
        return {
          primary: 'Unknown Item',
          secondary: '',
          status: null,
        };
    }
  };

  // Helper function to render a status badge
  const renderStatus = (status: string) => {
    if (!status) return null;
    const statusLower = status.toLowerCase();
    return (
      <Box
        component="span"
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '2px 6px',
          borderRadius: '4px',
          backgroundColor:
            statusLower.includes('completed') || statusLower.includes('delivered') || statusLower.includes('paid')
              ? theme.palette.success.light
              : statusLower.includes('pending') || statusLower.includes('processing') || statusLower.includes('transit')
                ? theme.palette.warning.light
                : theme.palette.error.light,
          color:
            statusLower.includes('completed') || statusLower.includes('delivered') || statusLower.includes('paid')
              ? theme.palette.success.dark
              : statusLower.includes('pending') || statusLower.includes('processing') || statusLower.includes('transit')
                ? theme.palette.warning.dark
                : theme.palette.error.dark,
          fontSize: '0.7rem',
          fontWeight: 600,
          whiteSpace: 'nowrap',
          textTransform: 'capitalize',
          maxWidth: '80px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {status}
      </Box>
    );
  };

  const hasActiveFilters =
    selectedChannels.length > 0 ||
    startDate !== null ||
    endDate !== null ||
    sortField !== 'created' ||
    sortDirection !== 'desc';

  return (
    <SearchContainer>
      <Box sx={{ width: '100%', position: 'relative' }}>
        <SearchField
          inputRef={inputRef}
          variant="outlined"
          placeholder={isMobile ? 'Search...' : 'Search customers, orders, products...'}
          fullWidth
          className={isMobile ? 'expanded' : ''}
          size="small"
          value={searchTerm}
          onChange={handleSearch}
          onFocus={handleFocus}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {searchTerm && (
                  <IconButton
                    edge="end"
                    size="small"
                    onClick={handleClear}
                    sx={{
                      color: 'white',
                      width: 28,
                      height: 28,
                      padding: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}
                {selectedChannels.length > 0 && (
                  <ChipsContainer>
                    {selectedChannels.length === 1 ? selectedChannels[0].label : `${selectedChannels.length} channels`}
                  </ChipsContainer>
                )}
                <FilterButtonContainer>
                  <Tooltip title="Search filters">
                    <IconButton
                      edge="end"
                      size="small"
                      onClick={handleOpenFilterPopover}
                      sx={{
                        color: 'white',
                        opacity: hasActiveFilters ? 1 : 0.7,
                        width: 28,
                        height: 28,
                        padding: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <FilterIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </FilterButtonContainer>
              </InputAdornment>
            ),
          }}
        />

        <Popover
          open={Boolean(filterAnchorEl)}
          anchorEl={filterAnchorEl}
          onClose={handleCloseFilterPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <FilterPopover>
            <Typography variant="body1" className="filter-section-title">
              Sort By
            </Typography>
            <FilterControlContainer>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControl fullWidth size="small">
                  <Select value={sortField} onChange={handleSortFieldChange}>
                    <MenuItem value="created">Created Date</MenuItem>
                    <MenuItem value="updated">Updated Date</MenuItem>
                    <MenuItem value="name">Name</MenuItem>
                  </Select>
                </FormControl>
                <IconButton onClick={handleSortDirectionChange} size="small">
                  {sortDirection === 'asc' ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </IconButton>
              </Box>
            </FilterControlContainer>

            <Typography variant="body1" className="filter-section-title">
              Date Range
            </Typography>
            <FilterControlContainer>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangeContainer>
                  <DatePicker
                    label="From"
                    value={startDate}
                    onChange={setStartDate}
                    slotProps={{
                      textField: {
                        size: 'small',
                        placeholder: 'MM/DD/YYYY',
                        InputLabelProps: {
                          shrink: true,
                        },
                        sx: {
                          '& .MuiInputBase-root': {
                            height: '36px',
                          },
                          '& .MuiInputBase-input': {
                            padding: '8px 14px',
                            height: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            lineHeight: '1',
                          },
                          '& input::placeholder': {
                            lineHeight: '1',
                            verticalAlign: 'middle',
                            opacity: 0.7,
                          },
                        },
                      },
                    }}
                  />
                  <DatePicker
                    label="To"
                    value={endDate}
                    onChange={setEndDate}
                    slotProps={{
                      textField: {
                        size: 'small',
                        placeholder: 'MM/DD/YYYY',
                        InputLabelProps: {
                          shrink: true,
                        },
                        sx: {
                          '& .MuiInputBase-root': {
                            height: '36px',
                          },
                          '& .MuiInputBase-input': {
                            padding: '8px 14px',
                            height: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            lineHeight: '1',
                          },
                          '& input::placeholder': {
                            lineHeight: '1',
                            verticalAlign: 'middle',
                            opacity: 0.7,
                          },
                        },
                      },
                    }}
                  />
                </DateRangeContainer>
              </LocalizationProvider>
            </FilterControlContainer>

            <Typography variant="body1" className="filter-section-title">
              Channels
            </Typography>
            <FilterControlContainer>
              <CustomSelect
                placeholder="All Channels"
                value={selectedChannels}
                onChange={handleChannelChange}
                schema={{ label: 'name', value: '_id' }}
                service="v1/objects/sales-channels"
                isClearable={true}
                search={true}
                multi={true}
              />
            </FilterControlContainer>

            <div className="filter-actions">
              <Button variant="outlined" size="small" onClick={handleClearFilters}>
                Clear
              </Button>
              <Button variant="contained" size="small" onClick={handleApplyFilters}>
                Apply
              </Button>
            </div>
          </FilterPopover>
        </Popover>

        <ClickAwayListener onClickAway={handleClickAway}>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              zIndex: 1100,
              overflow: 'visible',
            }}
          >
            {isOpen && searchTerm.length >= 2 && (
              <ResultsContainer
                elevation={4}
                className="UniversalSearch-results"
                sx={{
                  position: 'absolute',
                  width: '100%',
                  maxHeight: isMobile ? '50vh' : '65vh',
                  overflowY: 'auto !important',
                  display: 'block',
                  ...(isMobile && {
                    maxWidth: '100vw',
                    borderRadius: theme.shape.borderRadius,
                  }),
                }}
              >
                {!hasResults && (
                  <Box p={1.5} textAlign="center">
                    <Typography color="textSecondary" variant="body2" sx={{ fontSize: '0.875rem' }}>
                      No results found
                    </Typography>
                  </Box>
                )}

                {(Object.entries(searchResults) as [keyof SearchResults, any[]][]).map(([type, items]) => {
                  if (items.length === 0) return null;

                  // Determine if this type is expanded
                  const isExpanded = expandedTypes.includes(type as string);
                  // Show all items if expanded, otherwise limit based on mobile state
                  const displayItems = isExpanded ? items : items.slice(0, isMobile ? 2 : 3);

                  return (
                    <Box key={type}>
                      <SectionTitle variant="subtitle2">{getDisplayName(type)}</SectionTitle>
                      <List disablePadding>
                        {displayItems.map(item => {
                          const { primary, secondary, status } = getResultTexts(item, type);
                          return (
                            <ResultItem
                              key={item.id}
                              // @ts-ignore - Known issue with button prop type
                              button
                              onClick={() => handleItemClick(item, type)}
                              dense={!isMobile}
                            >
                              <ListItemIcon>{getIconForType(type)}</ListItemIcon>
                              <ListItemText
                                primary={
                                  <Box
                                    component="div"
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      width: '100%',
                                      position: 'relative',
                                      minHeight: '20px',
                                      gap: 1,
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      component="span"
                                      noWrap
                                      sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        flexGrow: 1,
                                        maxWidth: status ? 'calc(100% - 90px)' : '100%',
                                        fontSize: '0.875rem',
                                      }}
                                    >
                                      {primary}
                                    </Typography>
                                    {status && renderStatus(status)}
                                  </Box>
                                }
                                secondary={
                                  <Box
                                    sx={{
                                      fontSize: '0.75rem',
                                      mt: 0.25,
                                      display: 'flex',
                                      alignItems: 'center',
                                      flexWrap: 'wrap',
                                      color: theme.palette.text.secondary,
                                    }}
                                  >
                                    {secondary}
                                  </Box>
                                }
                              />
                            </ResultItem>
                          );
                        })}
                        {items.length > (isMobile ? 2 : 3) && !isExpanded && (
                          <SeeAllItem
                            // @ts-ignore - Known issue with button prop type
                            button
                            onClick={() => handleSeeAllClick(type)}
                            dense
                          >
                            <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>
                              See {items.length} more {getDisplayName(type).toLowerCase()}
                            </Typography>
                            <NavigateIcon fontSize="small" sx={{ ml: 0.5, fontSize: '1rem' }} />
                          </SeeAllItem>
                        )}
                        {isExpanded && items.length > 3 && (
                          <SeeAllItem
                            // @ts-ignore - Known issue with button prop type
                            button
                            onClick={() => handleSeeAllClick(type)}
                            dense
                          >
                            <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>
                              Show less
                            </Typography>
                            <ExpandLess fontSize="small" sx={{ ml: 0.5, fontSize: '1rem' }} />
                          </SeeAllItem>
                        )}
                      </List>
                      <Divider />
                    </Box>
                  );
                })}
              </ResultsContainer>
            )}
          </Box>
        </ClickAwayListener>
      </Box>
    </SearchContainer>
  );
};

// No need for mapDispatchToProps, the application connect handles this
export default connect({})(UniversalSearch);
