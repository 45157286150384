import { Box, ListItem, Paper, styled, TextField, Typography } from '@mui/material';

// Container for the search component
export const SearchContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  flex: '0 1 450px',
  margin: theme.spacing(0, 1),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    flex: '1 1 auto',
    margin: theme.spacing(0, 0.5),
    width: '100%',
  },
}));

// Container for the filter button
export const FilterButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: theme.spacing(0.5),
  '& .MuiSvgIcon-root': {
    color: theme.palette.common.white,
  },
}));

// Container for the filter popover
export const FilterPopover = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '300px',
  maxWidth: '90vw',
  '& .filter-section-title': {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    fontWeight: 500,
    '&:first-of-type': {
      marginTop: 0,
    },
  },
  '& .filter-actions': {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    gap: theme.spacing(1),
  },
}));

// Container for the filter controls
export const FilterControlContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(1),
  '& .MuiOutlinedInput-root': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    '& fieldset': {
      borderColor: theme.palette.divider,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.action.hover,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.primary,
  },
}));

// Date range container
export const DateRangeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(1),
  '& > *': {
    flex: '1 1 auto',
  },
}));

// Selected channel chip
export const SelectedChannelChip = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  borderRadius: theme.shape.borderRadius,
  fontSize: '0.75rem',
  padding: theme.spacing(0, 0.5),
  marginLeft: theme.spacing(0.5),
  height: 20,
  maxWidth: 120,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  color: theme.palette.common.white,
  '& .MuiSvgIcon-root': {
    fontSize: '0.875rem',
    marginLeft: theme.spacing(0.5),
  },
}));

// Multiple chip container
export const ChipsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  borderRadius: theme.shape.borderRadius,
  fontSize: '0.75rem',
  padding: theme.spacing(0, 0.5),
  marginLeft: theme.spacing(0.5),
  height: 20,
  color: theme.palette.common.white,
}));

// Search input field with custom styling
export const SearchField = styled(TextField)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.15)',
  borderRadius: theme.shape.borderRadius,
  width: '100%',
  '& .MuiInputBase-root': {
    color: theme.palette.common.white,
    width: '100%',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.25)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.light,
    },
  },
  '& .MuiInputAdornment-root .MuiSvgIcon-root': {
    color: theme.palette.common.white,
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    '&.expanded': {
      width: '100%',
    },
  },
}));

// Container for search results dropdown
export const ResultsContainer = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '100%',
  left: 0,
  right: 0,
  zIndex: 1200,
  padding: theme.spacing(0.5, 0),
  maxHeight: '65vh',
  overflowY: 'auto',
  overflowX: 'hidden',
  marginTop: theme.spacing(0.5),
  boxShadow: theme.shadows[8],
  display: 'block',
  '& .MuiDivider-root': {
    margin: 0,
  },
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.divider,
    borderRadius: '3px',
  },
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    maxHeight: '50vh',
    overflowY: 'auto',
    marginTop: theme.spacing(0.5),
    zIndex: 1500,
    width: '100%',
    boxShadow: theme.shadows[10],
  },
}));

// Section title for result categories
export const SectionTitle = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(0.25, 1.5),
  fontWeight: 600,
  fontSize: '0.85rem',
  position: 'sticky',
  top: 0,
  zIndex: 10,
}));

// Base styled list item for both result items and "see all" items
export const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(0.5, 1.5),
  cursor: 'pointer',
  position: 'relative',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '& .MuiListItemText-root': {
    margin: 0,
    position: 'relative',
  },
  '& .MuiListItemIcon-root': {
    minWidth: '32px',
  },
  [theme.breakpoints.down('md')]: {
    minHeight: '42px', // Slightly smaller touch target while still maintaining usability
  },
}));

// Styles for the "See All" item link
export const seeAllItemStyles = (theme: any) => ({
  justifyContent: 'flex-end',
  padding: theme.spacing(0.25, 1.5),
  color: theme.palette.primary.main,
  fontSize: '0.8rem',
});

// Styles for the result item
export const resultItemStyles = (theme: any) => ({
  padding: theme.spacing(0.5, 1.5),
  '& .MuiTypography-root': {
    fontSize: '0.875rem',
  },
  '& .MuiTypography-body2': {
    fontSize: '0.75rem',
  },
});

// Search icon button for mobile
export const SearchIconButton = styled(Box)(({ theme }) => ({
  display: 'none', // Hidden on desktop
  [theme.breakpoints.down('md')]: {
    display: 'none', // Now also hidden on mobile as search is always expanded
  },
}));
