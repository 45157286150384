import {
  faAbacus,
  faBallotCheck,
  faBarcodeRead,
  faBox,
  faBoxes,
  faCartFlatbedBoxes,
  faCashRegister,
  faChartLineUp,
  faChartPie,
  faClipboardList,
  faClipboardListCheck,
  faFileInvoiceDollar,
  faFileSignature,
  faHandHoldingDollar,
  faInboxOut,
  faIndustryWindows,
  faInventory,
  faMoneyBillsSimple,
  faMoneyCheckAlt,
  faPalletAlt,
  faPaperPlaneTop,
  faPenRuler,
  faPersonCarryBox,
  faScanner,
  faSearchLocation,
  faTicket,
  faWarehouseAlt,
  faWarning,
} from '@fortawesome/pro-regular-svg-icons';
import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AccountBalance,
  AddCircleOutline,
  AlternateEmail,
  Apps,
  BarChart as BarChartIcon,
  CallSplit,
  CardGiftcard,
  CastConnected,
  Category,
  ContactPhone,
  Description,
  DragIndicator,
  ExpandLess,
  ExpandMore,
  Feedback,
  Filter9Plus,
  InsertLink,
  LiveHelp,
  LocalShipping,
  Lock,
  Payment,
  PermMedia,
  PointOfSale,
  Poll,
  Public,
  PushPin,
  PushPinOutlined,
  RateReview,
  School,
  SettingsOutlined,
  ShoppingBasket,
  Speed as SpeedIcon,
  VerifiedUser,
  Widgets,
} from '@mui/icons-material';
import {
  Box,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import dispatchers from './dispatchers';
import apiClient from '../../../lib/api-client';
import connect from '../../../lib/connect';
import CustomBadge from '../../partial/CustomBadge';
import HelpAndFeedback from '../../partial/HelpAndFeedback';

// @ts-ignore
if (process.browser && window) {
  // @ts-ignore
  window.Tawk_API = window.Tawk_API || {};
}

// @ts-ignore
const tawkClient = process.browser && window?.Tawk_API;

const isDev = process.env.ENV !== 'production';
const expandedDrawerWidth = 250;
const collapsedDrawerWidth = 53;

/**
 * Standardized menu structure extracted from LeftDrawerItems component
 * This structure can be easily extended with new categories and items
 */
const menuStructure = {
  categories: [
    {
      name: 'ANALYTICS',
      shortName: 'ANT',
      isHeader: true,
      isExpandable: true,
      icon: 'Public',
      iconSource: 'mui',
      condition: "user.hasRule(['dashboard-preview']) || user.can('read', 'v1/objects/reports')",
      route: '/',
      items: [
        {
          name: 'Main Dashboard',
          icon: 'BarChartIcon',
          iconSource: 'mui',
          condition: "user.hasRule(['dashboard-preview'])",
          route: {
            pathname: '/',
          },
        },
        {
          name: 'Sales Dashboard',
          icon: 'faChartLineUp',
          iconSource: 'fa-regular',
          condition: "isDev && user.hasRule(['dashboard-preview', 'sales-representative', 'finance'])",
          route: {
            pathname: '/dashboards/sales',
          },
        },
        {
          name: 'Operations Stats',
          icon: 'SpeedIcon',
          iconSource: 'mui',
          condition: "user.hasRule(['dashboard-preview'])",
          route: {
            pathname: '/dashboards/ops-stats',
          },
        },
        {
          name: 'Reports',
          isExpandable: false,
          icon: 'Poll',
          iconSource: 'mui',
          condition: "user.can('read', 'v1/objects/reports')",
          route: '/reports',
        },
      ],
    },
    {
      name: 'ORDER MANAGEMENT',
      shortName: 'OMS',
      isHeader: true,
      isExpandable: true,
      items: [
        {
          name: 'Escalations',
          icon: 'faWarning',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['customer-support', 'sales-representative', 'finance'])",
          route: '/order-management/escalations',
        },
        {
          name: 'Customers',
          icon: 'faUsers',
          iconSource: 'fa-solid',
          condition: "user.hasRule(['customer-support', 'sales-representative', 'finance', 'sales-broker'])",
          route: '/order-management/customers',
        },
        {
          name: 'Orders',
          icon: 'ShoppingBasket',
          iconSource: 'mui',
          condition: "user.hasRule(['customer-support', 'sales-representative', 'finance'])",
          route: '/order-management/orders',
        },
        {
          name: 'Invoices',
          icon: 'faFileInvoiceDollar',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['finance'])",
          route: '/order-management/invoices',
          badge: 'state.badges.totalUnpaidInvoices',
        },
        {
          name: 'Refunds',
          icon: 'faFileInvoiceDollar',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['finance'])",
          route: '/order-management/refunds',
        },
        {
          name: 'Payments',
          icon: 'faMoneyCheckAlt',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['finance'])",
          route: '/order-management/payments',
        },
        {
          name: 'Incoming POs',
          icon: 'Description',
          iconSource: 'mui',
          condition: "user.hasRule(['sales-representative'])",
          route: '/order-management/incoming-pos',
          badge: 'state.badges.totalPendingPOs',
        },
        {
          name: 'Accounting',
          icon: 'AccountBalance',
          iconSource: 'mui',
          condition: "user.hasRule(['finance'])",
          route: '/order-management/accounting',
        },
        {
          name: 'New Order',
          icon: 'AddCircleOutline',
          iconSource: 'mui',
          condition:
            "user.hasRule(['customer-support', 'sales-representative', 'sales-broker', 'marketing', 'shipper-manager'])",
          route: '/order-management/new-order',
        },
      ],
    },
    {
      name: ' ORDER FULFILLMENT',
      shortName: 'FUL',
      isHeader: true,
      isExpandable: true,
      items: [
        {
          name: 'Fulfillment Queue',
          icon: 'LocalShipping',
          iconSource: 'mui',
          visibilityCondition: "shippingConfig?.fulfillmentMode === 'fulfillment-order' && user.hasRule(['shipper'])",
          route: '/order-fulfillment/queue',
          badge: 'state.badges.totalUnshippedOrders',
        },
        {
          name: 'Shipping Queue',
          icon: 'LocalShipping',
          iconSource: 'mui',
          visibilityCondition: "shippingConfig?.fulfillmentMode !== 'fulfillment-order' && user.hasRule(['shipper'])",
          route: '/order-fulfillment/shipping',
          badge: 'state.badges.totalUnshippedOrders',
        },
        {
          name: 'Batch Shipping',
          icon: 'Filter9Plus',
          iconSource: 'mui',
          condition: "user.hasRule(['shipper'])",
          route: '/order-fulfillment/batch-shipping',
        },
        {
          name: 'Scan Forms',
          icon: 'faBarcodeRead',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['shipper-manager'])",
          route: '/order-fulfillment/scan-forms',
        },
        {
          name: 'Pick Lists',
          icon: 'faClipboardListCheck',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['shipper'])",
          route: '/order-fulfillment/pick-lists',
        },
        {
          name: 'Shipments',
          icon: 'faPalletAlt',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['customer-support', 'sales-representative', 'shipper', 'finance'])",
          route: '/order-management/shipments',
        },
        {
          name: 'Standalone Label',
          icon: 'AddCircleOutline',
          iconSource: 'mui',
          condition: "user.hasRule(['shipper', 'shipper-manager'])",
          route: '/order-fulfillment/new-shipment',
        },
      ],
    },
    {
      name: 'WAREHOUSE MANAGEMENT',
      shortName: 'WMS',
      isHeader: true,
      isExpandable: true,
      items: [
        {
          name: 'Lookup',
          icon: 'faSearchLocation',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['inventory-manager', 'inventory-viewer'])",
          route: '/warehouse-management/lookup',
        },
        {
          name: 'Stock Counters',
          icon: 'faAbacus',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['inventory-manager', 'inventory-viewer'])",
          route: '/warehouse-management/counters',
        },
        {
          name: 'Inventory Log',
          icon: 'faClipboardList',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['inventory-manager', 'inventory-viewer'])",
          route: '/warehouse-management/inventory-log',
        },
        {
          name: 'Warehouses',
          icon: 'faWarehouseAlt',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['inventory-manager'])",
          route: '/warehouse-management/warehouses',
        },
        {
          name: 'Locations',
          icon: 'faInventory',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['inventory-manager'])",
          route: '/warehouse-management/locations',
        },
        {
          name: 'Transfers',
          icon: 'CallSplit',
          iconSource: 'mui',
          condition: "user.hasRule(['inventory-manager', 'supply-manager'])",
          route: '/warehouse-management/transfers',
          badge: 'state.badges.totalShippedTransfers',
        },
        {
          name: 'Tracker',
          icon: 'faScanner',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['inventory-manager'])",
          route: '/warehouse-management/inventory',
        },
      ],
    },
    {
      name: 'SUPPLY CHAIN',
      shortName: 'SCM',
      isHeader: true,
      isExpandable: true,
      items: [
        {
          name: 'Sales Forecasting',
          icon: 'faChartLineUp',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['supply-manager', 'finance'])",
          route: '/supply-chain/forecasting',
        },
        {
          name: 'Demand Planning',
          icon: 'faChartPie',
          iconSource: 'fa-regular',
          condition: "isDev && user.hasRule(['supply-manager', 'finance'])",
          route: '/supply-chain/demand-planning',
        },
        {
          name: 'Vendors',
          icon: 'ContactPhone',
          iconSource: 'mui',
          condition: "user.hasRule(['supply-manager'])",
          route: '/supply-chain/vendors',
        },
        {
          name: 'Products',
          icon: 'faBoxes',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['supply-manager', 'product-manager'])",
          route: '/supply-chain/products',
        },
        {
          name: 'Materials',
          icon: 'Category',
          iconSource: 'mui',
          condition: "user.hasRule(['supply-manager', 'product-manager'])",
          route: '/supply-chain/materials',
        },
        {
          name: 'Build Orders',
          icon: 'faIndustryWindows',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['inventory-manager', 'inventory-viewer', 'supply-manager'])",
          route: '/supply-chain/build-orders',
        },
        {
          name: 'Outgoing POs',
          icon: 'Description',
          iconSource: 'mui',
          condition: "user.hasRule(['supply-manager'])",
          route: '/supply-chain/outgoing-pos',
        },
        {
          name: 'Lots & Batches',
          icon: 'faBox',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['inventory-manager', 'inventory-viewer', 'supply-manager'])",
          route: '/supply-chain/stock-lots',
        },
        {
          name: 'Build Templates',
          icon: 'faPenRuler',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['inventory-manager', 'inventory-viewer', 'supply-manager'])",
          route: '/supply-chain/build-templates',
        },
        {
          name: 'Pending Approvals',
          icon: 'faBallotCheck',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['supply-manager'])",
          route: '/supply-chain/pending-review',
          badge: 'state.badges.totalPendingReview',
        },
      ],
    },
    {
      name: 'B2B PORTAL',
      shortName: 'B2B',
      isHeader: true,
      isExpandable: true,
      items: [
        {
          name: 'Applications',
          icon: 'faFileSignature',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['customer-support', 'sales-representative', 'marketing'])",
          route: '/b2b-portal/signup-applications',
        },
        {
          name: 'Invite Codes',
          icon: 'faTicket',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['customer-support', 'sales-representative', 'marketing'])",
          route: '/b2b-portal/invite-codes',
        },
        {
          name: 'Price Lists',
          icon: 'faHandHoldingDollar',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['supply-manager', 'product-manager'])",
          route: '/b2b-portal/price-lists',
        },
        {
          name: 'Coupons',
          icon: 'CardGiftcard',
          iconSource: 'mui',
          condition: "user.hasRule(['customer-support', 'sales-representative', 'marketing'])",
          route: '/b2b-portal/coupons',
        },
        {
          name: 'Reviews',
          icon: 'RateReview',
          iconSource: 'mui',
          condition: "user.hasRule(['customer-support', 'sales-representative', 'marketing'])",
          route: '/b2b-portal/reviews',
        },
      ],
    },
    {
      name: 'OTHER',
      shortName: 'OTH',
      isHeader: true,
      isExpandable: true,
      items: [
        {
          name: 'Email Templates',
          icon: 'AlternateEmail',
          iconSource: 'mui',
          condition: "user.hasRule(['developer'])",
          route: '/technology/email-templates',
        },
        {
          name: 'Email Senders',
          icon: 'faPaperPlaneTop',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['developer', 'finance', 'emails-log-viewer'])",
          route: '/technology/email-senders',
        },
        {
          name: 'Emails Log',
          icon: 'faInboxOut',
          iconSource: 'fa-regular',
          condition: "user.hasRule(['developer', 'finance', 'emails-log-viewer'])",
          route: '/technology/emails-log',
        },
        {
          name: 'Rules Engine',
          icon: 'Widgets',
          iconSource: 'mui',
          condition: "user.hasRule(['super-admin'])",
          route: '/rules-engine',
        },
        {
          name: 'Channels',
          icon: 'CastConnected',
          iconSource: 'mui',
          condition: "user.hasRule(['super-admin'])",
          route: '/channels',
        },
        {
          name: 'Adapters',
          icon: 'Apps',
          iconSource: 'mui',
          condition: "user.hasRule(['super-admin'])",
          route: '/adapters',
        },
        // {
        //   name: 'Feature Request',
        //   icon: 'Feedback',
        //   iconSource: 'mui',
        //   externalLink: 'https://kentro.io/feature-requests/',
        // },
        // {
        //   name: 'Documentation',
        //   icon: 'School',
        //   iconSource: 'mui',
        //   externalLink: 'https://kentro.readme.io',
        // },
      ],
    },
    {
      name: 'ADMINISTRATION',
      shortName: 'ADM',
      isHeader: true,
      isExpandable: true,
      items: [
        {
          name: 'Profile',
          icon: 'VerifiedUser',
          iconSource: 'mui',
          route: '/settings/profile',
        },
        {
          name: 'Account',
          icon: 'SettingsOutlined',
          iconSource: 'mui',
          condition: "user.hasRule(['super-admin'])",
          route: '/settings/account',
        },
        {
          name: 'Users',
          icon: 'VerifiedUser',
          iconSource: 'mui',
          condition: "user.can('manage', 'v1/objects/users')",
          route: '/users',
        },
        {
          name: 'Shipping',
          icon: 'LocalShipping',
          iconSource: 'mui',
          condition: "user.hasRule(['shipper-manager'])",
          route: '/settings/shipping',
        },
        {
          name: 'Billing',
          icon: 'faMoneyBillsSimple',
          iconSource: 'fa-regular',
          condition: "user.can('manage', 'v1/objects/billing')",
          route: '/billing',
        },
      ],
    },
    {
      name: 'Help',
      icon: 'LiveHelp',
      iconSource: 'mui',
      condition: 'tawkClient?.maximize',
      action: 'tawkClient?.maximize()',
    },
    {
      name: 'KENTRO ADMIN',
      shortName: 'ADM',
      isHeader: true,
      isExpandable: true,
      hideCondition: "!(location.host === 'localhost:3000' || location.host.split('.')[0] === 'admin')",
      route: '/kentro-admin',
      items: [
        {
          name: 'Email Templates',
          icon: 'PermMedia',
          iconSource: 'mui',
          route: '/kentro-admin/email-templates',
        },
      ],
    },
  ],
};

// Map of icon components by source and name
const iconMap = {
  mui: {
    Public,
    BarChartIcon,
    SpeedIcon,
    Poll,
    AddCircleOutline,
    ShoppingBasket,
    Description,
    Payment,
    AccountBalance,
    LocalShipping,
    Filter9Plus,
    CallSplit,
    Category,
    ContactPhone,
    PermMedia,
    RateReview,
    CardGiftcard,
    AlternateEmail,
    CastConnected,
    Apps,
    VerifiedUser,
    SettingsOutlined,
    InsertLink,
    Feedback,
    School,
    LiveHelp,
    Widgets,
    PointOfSale,
  },
  'fa-regular': {
    faWarning,
    faCartFlatbedBoxes,
    faFileInvoiceDollar,
    faClipboardListCheck,
    faBox,
    faBarcodeRead,
    faPersonCarryBox,
    faScanner,
    faClipboardList,
    faAbacus,
    faSearchLocation,
    faInventory,
    faWarehouseAlt,
    faPalletAlt,
    faChartLineUp,
    faChartPie,
    faInboxOut,
    faIndustryWindows,
    faBoxes,
    faHandHoldingDollar,
    faPenRuler,
    faBallotCheck,
    faTicket,
    faPaperPlaneTop,
    faMoneyBillsSimple,
    faCashRegister,
    faMoneyCheckAlt,
    faFileSignature,
  },
  'fa-solid': {
    faUsers,
  },
};

const displayCount = count => false; // Placeholder for badge display

const styles = (theme: any): any => ({
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    overflowX: 'hidden',
    width: collapsedDrawerWidth,
    position: 'fixed',
    zIndex: theme.zIndex.drawer,
  },
  drawerOpen: {
    width: expandedDrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      zIndex: theme.zIndex.drawer,
    },
  },
  drawerClose: {
    width: collapsedDrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: 0, // Mobile fully closed
      position: 'fixed',
      zIndex: theme.zIndex.drawer,
    },
  },
  drawerPaper: {
    position: 'fixed',
    height: '100vh',
    overflowX: 'hidden',
    overflowY: 'visible',
    backgroundColor: theme.palette.background.paper,
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '4px',
      display: 'block',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[400],
      borderRadius: '4px',
    },
  },
  drawerContent: {
    minHeight: '100vh',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    paddingBottom: theme.spacing(2),
    overflowY: 'auto',
  },
  toggleButton: {
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    zIndex: 1300,
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
  subheader: {
    padding: theme.spacing(1, 1.5),
    textAlign: 'center',
    background: theme.palette.background.paper,
    transition: theme.transitions.create(['padding', 'text-align'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    fontSize: '0.75rem',
    fontWeight: 'bold',
    lineHeight: '1.25rem',
    letterSpacing: '0.1em',
    color: theme.palette.text.secondary,
  },
  subheaderCollapsed: {
    padding: theme.spacing(1, 0),
    textAlign: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: collapsedDrawerWidth,
  },
  subheaderExpanded: {
    textAlign: 'left',
  },
  listItemText: {
    opacity: 1,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    margin: 0,
  },
  listItemTextHidden: {
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  list: {
    paddingTop: 58,
    width: '100%',
    flexGrow: 1,
    overflowY: 'visible',
  },
  nested: {
    paddingLeft: theme.spacing(3),
  },
  listItemIcon: {
    margin: 0,
    padding: 0,
    minWidth: 36,
    display: 'flex',
    justifyContent: 'center',
  },
  tooltipItem: {
    whiteSpace: 'nowrap',
  },
  listItem: {
    padding: 0,
  },
  listItemButton: {
    minHeight: 36,
    padding: theme.spacing(0.5, 1),
    position: 'relative',
    '&.Mui-selected': {
      backgroundColor: theme.palette.action.selected,
      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        width: 4,
        backgroundColor: theme.palette.primary.main,
      },
      '& .MuiListItemIcon-root': {
        color: theme.palette.primary.main,
      },
      '& .MuiTypography-root': {
        color: theme.palette.primary.main,
        fontWeight: 500,
      },
    },
  },
  pinButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    opacity: 0,
    transition: 'opacity 0.2s ease-in-out',
    color: theme.palette.text.secondary,
    '&.pinned': {
      opacity: 1,
      color: theme.palette.primary.main,
    },
  },
  listItemWithPin: {
    '&:hover .pinIcon': {
      opacity: 1,
    },
  },
});

interface Props extends GlobalProps {
  openLeftDrawerItem: any;
  toggleLeftDrawer: any;
}

// Helper functions
const getIcon = (iconName, iconSource) => {
  if (!iconName || !iconSource || !iconMap[iconSource]) return null;

  const Icon = iconMap[iconSource][iconName];
  if (!Icon) return null;

  if (iconSource.startsWith('fa-')) {
    return <FontAwesomeIcon icon={Icon} style={{ width: 24, height: 24 }} />;
  }

  return <Icon />;
};

const evaluateCondition = (condition, context) => {
  if (!condition) return true;
  try {
    // For safety in a real app, use a more secure evaluation method
    return Function(...Object.keys(context), `return ${condition}`)(...Object.values(context));
  } catch (error) {
    console.error('Error evaluating condition:', condition, error);
    return false;
  }
};

const getBadgeValue = (badgePath, state) => {
  if (!badgePath) return null;
  try {
    return Function('state', `return ${badgePath}`)(state);
  } catch (error) {
    console.error('Error getting badge value:', badgePath, error);
    return null;
  }
};

// Define item type for DnD
interface DraggableItem {
  id: string;
  name: string;
  icon: string;
  iconSource: string;
  route: any;
  isFavorite: boolean;
  index: number;
}

// Define the item type for drag and drop
const DRAGGABLE_ITEM_TYPE = 'PINNED_MENU_ITEM';

// Interface for the drag item
interface DragItem {
  index: number;
  type: string;
}

// Define drag props interface
interface DragProps {
  isDragging?: boolean;
  dragHandleProps?: {
    ref: React.RefObject<HTMLDivElement>;
  };
}

// Draggable menu item component for pinned items
const DraggableMenuItem = ({ item, index, moveItem, renderItem }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag({
    type: DRAGGABLE_ITEM_TYPE,
    item: (): DragItem => ({ index, type: DRAGGABLE_ITEM_TYPE }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: DRAGGABLE_ITEM_TYPE,
    hover: (draggedItem: DragItem, monitor) => {
      if (!ref.current) {
        return;
      }

      const dragIndex = draggedItem.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      if (!clientOffset) return;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here for better performance
      draggedItem.index = hoverIndex;
    },
  });

  // Initialize drag-and-drop refs
  drag(drop(ref));

  // Apply styles for dragging
  const opacity = isDragging ? 0.4 : 1;

  return (
    <div ref={ref} style={{ opacity }}>
      {renderItem(item, true, { isDragging, dragHandleProps: { ref } })}
    </div>
  );
};

const LeftDrawerItems = (props: Props) => {
  const {
    classes,
    user,
    router,
    redux: { state },
    openLeftDrawerItem,
    toggleLeftDrawer,
  } = props;

  const [helpAndFeedbackModal, setHelpAndFeedbackModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(props?.redux?.state?.user?.preferences?.configs?.drawerExpanded);
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(props.isMobile());
  const [hoveredItems, setHoveredItems] = useState<string[]>([]);
  const [mouseLeaveTimeouts, setMouseLeaveTimeouts] = useState<{ [key: string]: NodeJS.Timeout }>({});
  const [pinnedItems, setPinnedItems] = useState<string[]>(
    props?.redux?.state?.user?.preferences?.configs?.pinnedItems || []
  );
  const [savedExpandedMenus, setSavedExpandedMenus] = useState<string[]>(
    props?.redux?.state?.user?.preferences?.configs?.expandedMenus || []
  );

  // Add additional state for drag and drop
  const [draggablePinnedItems, setDraggablePinnedItems] = useState<DraggableItem[]>([]);

  const { pathname } = router;
  const { hideDisabledMenus, configs: tenantConfigs = {} } = state.user;
  const shippingConfig = tenantConfigs?.shipping || {};
  const showFullDrawer = isMobile ? state.leftDrawer : isExpanded || (isHovered && !isMobile);

  // Handle resize
  useEffect(() => {
    const checkIsMobile = () => {
      const mobileView = props.isMobile();
      setIsMobile(mobileView);
      if (mobileView) {
        setIsExpanded(false);
        // On mobile, set leftDrawer to false by default
        if (state.leftDrawer) {
          toggleLeftDrawer();
        }
      }
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  // Sync with redux state - only when redux state changes, not on initial load
  useEffect(() => {
    if (!isMobile && state.leftDrawer !== isExpanded) {
      setIsExpanded(state.leftDrawer);

      // Save user preference when drawer state changes deliberately (not on hover)
      // Only save preferences on desktop, not on mobile
      if (state?.user?.preferences?._id && !isMobile) {
        try {
          apiClient.rest.service('v1/objects/user-preferences').patch(state.user.preferences._id, {
            configs: {
              ...(state.user.preferences.configs || {}),
              drawerExpanded: state.leftDrawer,
            },
          });
        } catch (error) {
          console.error('Error saving drawer state preference:', error);
        }
      }
    }
  }, [state.leftDrawer, isMobile]);

  // Load pinned items from user preferences
  useEffect(() => {
    if (state?.user?.preferences?.configs) {
      if (state.user.preferences.configs.pinnedItems) {
        setPinnedItems(state.user.preferences.configs.pinnedItems);

        // Convert string array to objects for draggable items
        const items: DraggableItem[] = state.user.preferences.configs.pinnedItems
          .map((itemKey, index) => {
            const [iconSource, icon, name, routeString] = itemKey.split('|');

            let route;
            try {
              route = routeString.startsWith('/') ? routeString : JSON.parse(routeString);
            } catch (e) {
              route = routeString;
            }

            return {
              id: itemKey,
              name,
              icon,
              iconSource,
              route,
              isFavorite: true,
              index,
            };
          })
          .filter(item => item.name && item.icon);

        setDraggablePinnedItems(items);
      }

      // Load saved expanded menus preferences
      if (state.user.preferences.configs.expandedMenus) {
        setSavedExpandedMenus(state.user.preferences.configs.expandedMenus);

        // Set leftDrawOpenItem to the first saved expanded menu if any
        if (state.user.preferences.configs.expandedMenus.length > 0 && !state.leftDrawOpenItem) {
          openLeftDrawerItem(state.user.preferences.configs.expandedMenus[0]);
        }
      }
    }
  }, [state?.user?.preferences?.configs]);

  // Event handlers
  const toggleItem = (name = '') => {
    return () => {
      let updatedExpandedMenus = [...savedExpandedMenus];

      if (state.leftDrawOpenItem === name) {
        // Menu is being closed, remove it from saved list
        updatedExpandedMenus = updatedExpandedMenus.filter(item => item !== name);
        openLeftDrawerItem();
      } else {
        // Menu is being opened, add it to saved list if not already there
        if (!updatedExpandedMenus.includes(name)) {
          updatedExpandedMenus.push(name);
          openLeftDrawerItem(name);
        } else {
          updatedExpandedMenus = updatedExpandedMenus.filter(item => item !== name);
        }
      }

      // Update state
      setSavedExpandedMenus(updatedExpandedMenus);

      // Save to user preferences only on desktop, not on mobile
      if (state?.user?.preferences?._id && !isMobile) {
        try {
          apiClient.rest.service('v1/objects/user-preferences').patch(state.user.preferences._id, {
            configs: {
              ...(state.user.preferences.configs || {}),
              expandedMenus: updatedExpandedMenus,
            },
          });
        } catch (error) {
          console.error('Error saving expanded menus preference:', error);
        }
      }
    };
  };

  const handleMouseEnter = () => {
    if (!isMobile && !isExpanded) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);

    // Clear all hovered items to ensure menus close when drawer is minimized
    setHoveredItems([]);

    // Clear any pending timeouts
    Object.keys(mouseLeaveTimeouts).forEach(itemName => {
      clearTimeout(mouseLeaveTimeouts[itemName]);
    });
    setMouseLeaveTimeouts({});
  };

  const handleItemMouseEnter = (name: string) => {
    if (showFullDrawer && !isMobile) {
      // Clear any existing timeout for this item
      if (mouseLeaveTimeouts[name]) {
        clearTimeout(mouseLeaveTimeouts[name]);
        setMouseLeaveTimeouts(prev => {
          const updated = { ...prev };
          delete updated[name];
          return updated;
        });
      }

      // Clear timeouts for all items
      Object.keys(mouseLeaveTimeouts).forEach(itemName => {
        clearTimeout(mouseLeaveTimeouts[itemName]);
      });
      setMouseLeaveTimeouts({});

      setHoveredItems(prev => {
        if (!prev.includes(name)) {
          return [...prev, name];
        }
        return prev;
      });
    }
  };

  const handleItemMouseLeave = (name: string, event: React.MouseEvent) => {
    // Don't remove the item from hoveredItems if the mouse is moving to a child
    const currentTarget = event.currentTarget as HTMLElement;
    const relatedTarget = event.relatedTarget as HTMLElement;

    // If relatedTarget is null or not an element, don't do anything
    if (!relatedTarget) return;

    // Check if the mouse is moving to any submenu element
    const menuElements = document.querySelectorAll('.MuiCollapse-root, .MuiListItem-root, .MuiList-root');
    let movingToSubmenu = false;

    // Safely check if related target is within any menu elements
    menuElements.forEach(el => {
      if (el && relatedTarget && el instanceof Node && relatedTarget instanceof Node) {
        try {
          if (el.contains(relatedTarget)) {
            // Check if this menu element belongs to the current hoveredItems
            const menuItemName = el.getAttribute('data-menu-name');
            if (menuItemName === name || el.closest(`[data-menu-name="${name}"]`)) {
              movingToSubmenu = true;
            }
          }
        } catch (error) {
          console.error('Error in contains check:', error);
        }
      }
    });

    if (movingToSubmenu) return;

    // Also check if the mouse is moving to a child element of the current menu item
    try {
      if (
        currentTarget &&
        relatedTarget &&
        currentTarget instanceof Node &&
        relatedTarget instanceof Node &&
        currentTarget.contains &&
        typeof currentTarget.contains === 'function'
      ) {
        if (currentTarget.contains(relatedTarget)) {
          return; // Mouse is moving to a child element, don't close the menu
        }
      }
    } catch (error) {
      console.error('Error checking if currentTarget contains relatedTarget:', error);
    }

    // Set a timeout before removing the item, giving time to move to subitems
    const timeout = setTimeout(() => {
      setHoveredItems(prev => prev.filter(item => item !== name));
    }, 150); // Reduced from 300ms to 150ms for better responsiveness

    setMouseLeaveTimeouts(prev => ({
      ...prev,
      [name]: timeout,
    }));
  };

  // Clear all timeouts on unmount
  useEffect(() => {
    return () => {
      Object.values(mouseLeaveTimeouts).forEach(timeout => clearTimeout(timeout));
    };
  }, [mouseLeaveTimeouts]);

  const toggleHelpAndFeedbackModal = () => {
    setHelpAndFeedbackModal(!helpAndFeedbackModal);
  };

  // Handler to move items during drag and drop
  const moveItem = (dragIndex: number, hoverIndex: number) => {
    const draggedItem = draggablePinnedItems[dragIndex];
    if (!draggedItem) return;

    const newItems = [...draggablePinnedItems];

    // Remove the item from its original position
    newItems.splice(dragIndex, 1);

    // Insert the item at the new position
    newItems.splice(hoverIndex, 0, draggedItem);

    // Update the indexes
    const updatedItems = newItems.map((item, index) => ({
      ...item,
      index,
    }));

    setDraggablePinnedItems(updatedItems);

    // Also update pinnedItems array to maintain consistency
    const newPinnedItems = updatedItems.map(item => item.id);
    setPinnedItems(newPinnedItems);

    // Save to user preferences
    if (state?.user?.preferences?._id) {
      try {
        apiClient.rest.service('v1/objects/user-preferences').patch(state.user.preferences._id, {
          configs: {
            ...(state.user.preferences.configs || {}),
            pinnedItems: newPinnedItems,
          },
        });
      } catch (error) {
        console.error('Error saving pinned items order:', error);
      }
    }
  };

  // Toggle pin for a menu item
  const handleTogglePin = (item, event) => {
    event.stopPropagation(); // Prevent item click

    const itemKey = `${item.iconSource}|${item.icon}|${item.name}|${typeof item.route === 'string' ? item.route : JSON.stringify(item.route)}`;

    const newPinnedItems = [...pinnedItems];
    const existingIndex = newPinnedItems.indexOf(itemKey);

    if (existingIndex >= 0) {
      newPinnedItems.splice(existingIndex, 1);

      // Also update draggablePinnedItems
      setDraggablePinnedItems(prevItems =>
        prevItems.filter(item => item.id !== itemKey).map((item, index) => ({ ...item, index }))
      );
    } else {
      newPinnedItems.push(itemKey);

      // Add to draggablePinnedItems
      const [iconSource, icon, name, routeString] = itemKey.split('|');

      let route;
      try {
        route = routeString.startsWith('/') ? routeString : JSON.parse(routeString);
      } catch (e) {
        route = routeString;
      }

      const newItem: DraggableItem = {
        id: itemKey,
        name,
        icon,
        iconSource,
        route,
        isFavorite: true,
        index: draggablePinnedItems.length,
      };

      setDraggablePinnedItems(prevItems => [...prevItems, newItem]);
    }

    setPinnedItems(newPinnedItems);

    // Save to user preferences
    if (state?.user?.preferences?._id) {
      try {
        apiClient.rest.service('v1/objects/user-preferences').patch(state.user.preferences._id, {
          configs: {
            ...(state.user.preferences.configs || {}),
            pinnedItems: newPinnedItems,
          },
        });
      } catch (error) {
        console.error('Error saving pinned items preference:', error);
      }
    }
  };

  // Check if an item is pinned
  const isItemPinned = item => {
    const itemKey = `${item.iconSource}|${item.icon}|${item.name}|${typeof item.route === 'string' ? item.route : JSON.stringify(item.route)}`;
    return pinnedItems.includes(itemKey);
  };

  // Check if an item is in the favorites section
  const isInFavoritesSection = item => {
    // If we're in the PINNED section, don't show the pin option
    const categoryPath = item.categoryPath || '';
    return categoryPath.startsWith('PINNED');
  };

  // Helper rendering functions
  const renderMenuIcon = (icon, badge = null) => {
    if (badge) {
      return (
        <CustomBadge color={badge ? 'secondary' : 'default'} badgeContent={displayCount(badge)}>
          {icon}
        </CustomBadge>
      );
    }
    return icon;
  };

  const renderMenuItemContent = (
    icon,
    text,
    isNested = false,
    badge = null,
    isDisabled = false,
    isSelected = false
  ) => {
    return (
      <>
        <ListItemIcon
          className={classes.listItemIcon}
          sx={{
            ml: isNested ? 1 : 0,
            color: isSelected ? theme => theme.palette.primary.main : 'inherit',
          }}
        >
          {renderMenuIcon(icon, badge)}
        </ListItemIcon>
        {showFullDrawer && (
          <ListItemText
            primary={text}
            inset
            primaryTypographyProps={{
              noWrap: true,
              color: isDisabled ? 'text.disabled' : isSelected ? 'primary.main' : 'inherit',
              fontWeight: isSelected ? 500 : 'inherit',
            }}
            className={showFullDrawer ? classes.listItemText : classes.listItemTextHidden}
          />
        )}
      </>
    );
  };

  const wrapWithTooltip = (element, text, isDisabled = false) => {
    if (!showFullDrawer && !isDisabled) {
      return (
        <Tooltip title={<span className={classes.tooltipItem}>{text}</span>} placement="right">
          {element}
        </Tooltip>
      );
    }
    return element;
  };

  // Create context for evaluating conditions
  const conditionContext = { user, state, shippingConfig, isDev, tawkClient };

  // Helper function to check if any child route is selected
  const isAnyChildSelected = items => {
    if (!items || !items.length) return false;

    return items.some(item => {
      // Check if this item is selected
      if (typeof item.route === 'string') {
        if (pathname === item.route) return true;
      } else if (item.route && typeof item.route === 'object' && item.route.pathname) {
        if (
          pathname === item.route.pathname &&
          (!item.route.query || JSON.stringify(router.query) === JSON.stringify(item.route.query))
        ) {
          return true;
        }
      }

      // Check if any children of this item are selected (recursive)
      if (item.items && item.items.length) {
        return isAnyChildSelected(item.items);
      }

      return false;
    });
  };

  // Render menu items
  const renderMenuItem = (item, isNested = false, categoryPath = '') => {
    // Add category path to the item for tracking its location
    const itemWithPath = {
      ...item,
      categoryPath: categoryPath ? `${categoryPath}/${item.name}` : item.name,
    };

    const icon = getIcon(itemWithPath.icon, itemWithPath.iconSource);
    const isVisible = evaluateCondition(itemWithPath.visibilityCondition, conditionContext);
    const isDisabled = !evaluateCondition(itemWithPath.condition, conditionContext);
    const shouldHide = itemWithPath.hideCondition
      ? evaluateCondition(itemWithPath.hideCondition, conditionContext)
      : false;
    const badgeValue = itemWithPath.badge ? getBadgeValue(itemWithPath.badge, state) : null;
    const pinned = isItemPinned(itemWithPath);
    const inFavorites = isInFavoritesSection(itemWithPath);
    const isFavoriteItem = itemWithPath.isFavorite === true;

    if (shouldHide || !isVisible || (isDisabled && hideDisabledMenus)) return null;

    // If this menu item has subitems (expandable menu item)
    if (itemWithPath.isExpandable && itemWithPath.items) {
      const isItemHovered = hoveredItems.includes(itemWithPath.name);
      const isSavedExpanded = savedExpandedMenus.includes(itemWithPath.name);
      const isOpen = state.leftDrawOpenItem === itemWithPath.name || isItemHovered;
      const isDirectlySelected = pathname === itemWithPath.route;
      const hasSelectedChild = isAnyChildSelected(itemWithPath.items);
      // Item is selected if directly selected or if any child is selected
      const isSelected = isDirectlySelected || hasSelectedChild;

      return (
        <React.Fragment key={itemWithPath.name}>
          <ListItem
            disablePadding
            disabled={isDisabled}
            selected={isSelected}
            className={classes.listItem}
            onMouseEnter={e => handleItemMouseEnter(itemWithPath.name)}
            onMouseLeave={e => handleItemMouseLeave(itemWithPath.name, e)}
            data-menu-name={itemWithPath.name}
          >
            {wrapWithTooltip(
              <ListItemButton
                onClick={!isDisabled ? toggleItem(itemWithPath.name) : () => {}}
                className={classes.listItemButton}
              >
                {renderMenuItemContent(icon, itemWithPath.name, isNested, null, isDisabled, isSelected)}
                {showFullDrawer &&
                  (isSavedExpanded ? <Lock fontSize="small" /> : isOpen ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>,
              itemWithPath.name,
              isDisabled
            )}
          </ListItem>
          <Collapse in={isOpen} timeout="auto" unmountOnExit data-menu-name={itemWithPath.name}>
            <List
              disablePadding
              onMouseEnter={() => handleItemMouseEnter(itemWithPath.name)}
              onMouseLeave={e => handleItemMouseLeave(itemWithPath.name, e)}
              sx={{ '& *': { pointerEvents: 'auto' } }}
              data-menu-name={itemWithPath.name}
            >
              {itemWithPath.items.map(subItem => renderMenuItem(subItem, true, itemWithPath.categoryPath))}
            </List>
          </Collapse>
        </React.Fragment>
      );
    }

    // Determine route and selection state
    let isSelected = false;
    let onClick;

    if (itemWithPath.externalLink) {
      onClick = () => window.open(itemWithPath.externalLink, '_blank');
    } else if (itemWithPath.action) {
      onClick = () => {
        const action = Function('tawkClient', `return ${itemWithPath.action}`);
        action(tawkClient);
      };
    } else if (typeof itemWithPath.route === 'string') {
      isSelected = pathname === itemWithPath.route;
      onClick = () => router.push(itemWithPath.route);
    } else if (itemWithPath.route) {
      // Handle both string and object routes for pinned items
      if (typeof itemWithPath.route === 'object' && itemWithPath.route.pathname) {
        isSelected =
          pathname === itemWithPath.route.pathname &&
          (!itemWithPath.route.query || JSON.stringify(router.query) === JSON.stringify(itemWithPath.route.query));
        onClick = () => router.push(itemWithPath.route);
      } else {
        isSelected = pathname === itemWithPath.route;
        onClick = () => router.push(itemWithPath.route);
      }
    }

    // Show pin icon based on context:
    // - Regular items: show pin/unpin for non-Help, non-action, non-externalLink items
    // - Favorite items: always show unpin button
    const showPinIcon =
      !itemWithPath.action && !itemWithPath.externalLink && itemWithPath.name !== 'Help' && !inFavorites;
    const showUnpinIcon = isFavoriteItem;

    return (
      <ListItem
        key={itemWithPath.name}
        disablePadding
        disabled={isDisabled}
        selected={isSelected}
        className={`${isNested ? classes.nested : ''} ${classes.listItem} ${showPinIcon || showUnpinIcon ? classes.listItemWithPin : ''}`}
      >
        {wrapWithTooltip(
          <ListItemButton onClick={!isDisabled ? onClick : () => {}} className={classes.listItemButton}>
            {renderMenuItemContent(icon, itemWithPath.name, isNested, badgeValue, isDisabled, isSelected)}
            {showFullDrawer && showPinIcon && (
              <Box
                component="div"
                className={`${classes.pinButton} pinIcon ${pinned ? 'pinned' : ''}`}
                onClick={e => handleTogglePin(itemWithPath, e)}
              >
                {pinned ? <PushPin fontSize="small" /> : <PushPinOutlined fontSize="small" />}
              </Box>
            )}
            {showFullDrawer && showUnpinIcon && (
              <Box
                component="div"
                className={`${classes.pinButton} pinIcon pinned`}
                onClick={e => handleTogglePin(itemWithPath, e)}
                title="Remove from favorites"
              >
                <PushPin fontSize="small" />
              </Box>
            )}
          </ListItemButton>,
          itemWithPath.name,
          isDisabled
        )}
      </ListItem>
    );
  };

  // Render a draggable menu item
  const renderDraggableMenuItem = (item, index) => {
    return (
      <DraggableMenuItem
        key={`${item.name}-${index}`}
        item={item}
        index={index}
        moveItem={moveItem}
        renderItem={(itemData, isPinned, dragProps: DragProps = {}) => {
          const { isDragging, dragHandleProps } = dragProps;

          const isSelected =
            typeof itemData.route === 'string'
              ? pathname === itemData.route
              : itemData.route && itemData.route.pathname === pathname;

          return (
            <ListItem
              disablePadding
              className={`${classes.listItem} ${classes.listItemWithPin}`}
              selected={isSelected}
              sx={{
                cursor: 'move',
                border: isDragging ? '1px dashed #ccc' : 'none',
                backgroundColor: isDragging ? 'rgba(0, 0, 0, 0.05)' : 'transparent',
              }}
            >
              <ListItemButton
                onClick={() => {
                  if (typeof itemData.route === 'string') {
                    router.push(itemData.route);
                  } else if (itemData.route && typeof itemData.route === 'object') {
                    router.push(itemData.route);
                  }
                }}
                className={classes.listItemButton}
                selected={isSelected}
              >
                <ListItemIcon
                  className={classes.listItemIcon}
                  sx={{ color: isSelected ? theme => theme.palette.primary.main : 'inherit' }}
                >
                  {getIcon(itemData.icon, itemData.iconSource)}
                </ListItemIcon>
                {showFullDrawer && (
                  <>
                    <Box display="flex" alignItems="center" width="100%">
                      {!isMobile ? (
                        <DragIndicator
                          sx={{
                            mr: 1,
                            color: 'text.secondary',
                            opacity: 0.5,
                            fontSize: '1rem',
                          }}
                        />
                      ) : null}
                      <ListItemText
                        primary={itemData.name}
                        inset
                        primaryTypographyProps={{
                          noWrap: true,
                          color: isSelected ? 'primary.main' : 'inherit',
                          fontWeight: isSelected ? 500 : 'inherit',
                        }}
                        className={classes.listItemText}
                      />
                    </Box>
                    <Box
                      component="div"
                      className={`${classes.pinButton} pinIcon pinned`}
                      onClick={e => handleTogglePin(itemData, e)}
                      title="Remove from favorites"
                    >
                      <PushPin fontSize="small" />
                    </Box>
                  </>
                )}
              </ListItemButton>
            </ListItem>
          );
        }}
      />
    );
  };

  // Render category (section)
  const renderCategory = (category, parentPath = '', index = 0) => {
    // Check if category should be hidden first
    const shouldHide = category.hideCondition ? evaluateCondition(category.hideCondition, conditionContext) : false;
    if (shouldHide) return null;

    // Add a divider above the Help item
    if (category.name === 'Help' || category.name === 'KENTRO ADMIN') {
      return (
        <React.Fragment key={category.name}>
          <Box
            sx={{
              height: '1px',
              backgroundColor: theme => theme.palette.divider,
              mx: showFullDrawer ? 2 : 0.5,
              my: 1,
            }}
          />
          {renderCategoryContent(category, parentPath)}
        </React.Fragment>
      );
    }

    // Add a divider after the PINNED section (index 0)
    if (index === 0 && category.name === 'PINNED') {
      return (
        <React.Fragment key={category.name}>
          {renderCategoryContent(category, parentPath)}
          <Box
            sx={{
              height: '1px',
              backgroundColor: theme => theme.palette.divider,
              mx: showFullDrawer ? 2 : 0.5,
              my: 1,
            }}
          />
        </React.Fragment>
      );
    }

    // Add a divider after the Analytics section
    if (category.name === 'ANALYTICS') {
      return (
        <React.Fragment key={category.name}>
          {renderCategoryContent(category, parentPath)}
          <Box
            sx={
              {
                // height: '1px',
                // backgroundColor: theme => theme.palette.divider,
                // mx: showFullDrawer ? 2 : 0.5,
                // my: 1,
              }
            }
          />
        </React.Fragment>
      );
    }

    return renderCategoryContent(category, parentPath);
  };

  // Extract the actual category rendering logic to a separate function
  const renderCategoryContent = (category, parentPath = '') => {
    const categoryPath = parentPath ? `${parentPath}/${category.name}` : category.name;

    if (category.isHeader) {
      // Handle expandable headers
      if (category.isExpandable) {
        const isItemHovered = hoveredItems.includes(category.name);
        const isSavedExpanded = savedExpandedMenus.includes(category.name);
        const isOpen = state.leftDrawOpenItem === category.name || isItemHovered || isSavedExpanded;

        // Special styling for PINNED category
        const isPinnedHeader = category.name === 'PINNED';

        // Check if any child is selected for visual highlighting
        const hasSelectedChild = category.items && isAnyChildSelected(category.items);

        return (
          <React.Fragment key={category.name}>
            <ListItem
              disablePadding
              className={classes.listItem}
              selected={hasSelectedChild}
              onMouseEnter={e => handleItemMouseEnter(category.name)}
              onMouseLeave={e => handleItemMouseLeave(category.name, e)}
              data-menu-name={category.name}
            >
              <ListItemButton
                onClick={toggleItem(category.name)}
                className={classes.listItemButton}
                selected={hasSelectedChild}
                sx={{
                  backgroundColor: theme => theme.palette.grey[100],
                  '&:hover': { backgroundColor: theme => theme.palette.grey[200] },
                }}
              >
                <Box
                  className={`${classes.subheader} ${showFullDrawer ? classes.subheaderExpanded : classes.subheaderCollapsed}`}
                  sx={{
                    width: '100%',
                    padding: 0,
                    display: 'flex',
                    justifyContent: showFullDrawer ? 'space-between' : 'center',
                    alignItems: 'center',
                    color: hasSelectedChild ? theme => theme.palette.primary.main : 'inherit',
                  }}
                >
                  {isPinnedHeader && showFullDrawer && (
                    <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                      <PushPin fontSize="small" sx={{ mr: 1, color: theme => theme.palette.primary.main }} />
                      {category.name}
                    </Box>
                  )}
                  {(!isPinnedHeader || !showFullDrawer) &&
                    (showFullDrawer ? category.name : category.shortName || category.name.substring(0, 2))}
                  {showFullDrawer &&
                    (isSavedExpanded ? <Lock fontSize="small" /> : isOpen ? <ExpandLess /> : <ExpandMore />)}
                </Box>
              </ListItemButton>
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit data-menu-name={category.name}>
              <List
                disablePadding
                onMouseEnter={() => handleItemMouseEnter(category.name)}
                onMouseLeave={e => handleItemMouseLeave(category.name, e)}
                sx={{ '& *': { pointerEvents: 'auto' } }}
                data-menu-name={category.name}
              >
                {category.isDraggable
                  ? draggablePinnedItems.map((item, index) => renderDraggableMenuItem(item, index))
                  : category.items?.map(item => renderMenuItem(item, false, categoryPath))}
              </List>
            </Collapse>
          </React.Fragment>
        );
      }

      // Special case for PINNED header (now with drag and drop)
      const isPinnedHeader = category.name === 'PINNED';

      // For the PINNED category, we render draggable items
      if (isPinnedHeader && category.isDraggable) {
        return (
          <React.Fragment key={category.name}>
            {/* Render draggable items for PINNED (disable dragging on mobile) */}
            {showFullDrawer
              ? isMobile
                ? draggablePinnedItems.map((item, index) => renderMenuItem(item, false, categoryPath))
                : draggablePinnedItems.map((item, index) => renderDraggableMenuItem(item, index))
              : category.items?.map(item => renderMenuItem(item, false, categoryPath))}
          </React.Fragment>
        );
      }

      // Non-expandable header (original behavior)
      return (
        <React.Fragment key={category.name}>
          <Box
            component="li"
            className={`${classes.subheader} ${showFullDrawer ? classes.subheaderExpanded : classes.subheaderCollapsed}`}
            sx={{
              display: 'flex',
              justifyContent: showFullDrawer ? 'flex-start' : 'center',
              alignItems: 'center',
              backgroundColor: theme => theme.palette.grey[100],
              py: 0.75,
            }}
          >
            {isPinnedHeader && showFullDrawer && (
              <PushPin fontSize="small" sx={{ mr: 1, color: theme => theme.palette.primary.main }} />
            )}
            {showFullDrawer ? category.name : category.shortName || category.name.substring(0, 2)}
          </Box>
          {category.items?.map(item => renderMenuItem(item, false, categoryPath))}
        </React.Fragment>
      );
    }

    // Expandable category
    if (category.isExpandable) {
      const isVisible = evaluateCondition(category.visibilityCondition, conditionContext);
      const isDisabled = !evaluateCondition(category.condition, conditionContext);
      const shouldHide = category.hideCondition ? evaluateCondition(category.hideCondition, conditionContext) : false;

      if (shouldHide || !isVisible || (isDisabled && hideDisabledMenus)) return null;

      const icon = getIcon(category.icon, category.iconSource);
      const isItemHovered = hoveredItems.includes(category.name);
      const isSavedExpanded = savedExpandedMenus.includes(category.name);
      const isOpen = state.leftDrawOpenItem === category.name || isItemHovered || isSavedExpanded;

      const isDirectlySelected = pathname === category.route;
      const hasSelectedChild = category.items && isAnyChildSelected(category.items);
      // Category is selected if directly selected or if any child is selected
      const isSelected = isDirectlySelected || hasSelectedChild;

      return (
        <React.Fragment key={category.name}>
          <ListItem
            disablePadding
            disabled={isDisabled}
            selected={isSelected}
            className={classes.listItem}
            onMouseEnter={e => handleItemMouseEnter(category.name)}
            onMouseLeave={e => handleItemMouseLeave(category.name, e)}
            data-menu-name={category.name}
          >
            {wrapWithTooltip(
              <ListItemButton onClick={toggleItem(category.name)} className={classes.listItemButton}>
                {renderMenuItemContent(icon, category.name, false, null, isDisabled, isSelected)}
                {showFullDrawer &&
                  (isSavedExpanded ? <Lock fontSize="small" /> : isOpen ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>,
              category.name,
              isDisabled
            )}
          </ListItem>
          <Collapse in={isOpen} timeout="auto" unmountOnExit data-menu-name={category.name}>
            <List
              disablePadding
              onMouseEnter={() => handleItemMouseEnter(category.name)}
              onMouseLeave={e => handleItemMouseLeave(category.name, e)}
              sx={{ '& *': { pointerEvents: 'auto' } }}
              data-menu-name={category.name}
            >
              {category.items?.map(item => renderMenuItem(item, true, categoryPath))}
            </List>
          </Collapse>
        </React.Fragment>
      );
    }

    // Regular menu item
    return renderMenuItem(category, false, parentPath);
  };

  // Get all categories including dynamic favorites section
  const getAllCategories = () => {
    const categories = [...menuStructure.categories];
    const favoritesCategory = getFavoritesCategory();

    if (favoritesCategory && favoritesCategory.items && favoritesCategory.items.length > 0) {
      // Insert favorites at the top (index 0)
      categories.splice(0, 0, favoritesCategory);
    }

    return categories;
  };

  // Create favorited items section from pinned items
  const getFavoritesCategory = () => {
    if (draggablePinnedItems.length === 0) return null;

    // Return a category structure that matches other categories in menuStructure
    return {
      name: 'PINNED',
      shortName: 'PIN',
      isHeader: true,
      isExpandable: false,
      items: draggablePinnedItems,
      isDraggable: true,
    };
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={isMobile ? state.leftDrawer : true}
        onClose={isMobile ? toggleLeftDrawer : undefined}
        className={`${classes.drawer} ${showFullDrawer ? classes.drawerOpen : classes.drawerClose}`}
        classes={{
          paper: `${classes.drawerPaper} ${showFullDrawer ? classes.drawerOpen : classes.drawerClose}`,
        }}
        onMouseEnter={isMobile ? undefined : handleMouseEnter}
        onMouseLeave={isMobile ? undefined : handleMouseLeave}
        sx={{
          '& .MuiDrawer-paper': {
            width: showFullDrawer ? expandedDrawerWidth : isMobile ? 0 : collapsedDrawerWidth,
            boxSizing: 'border-box',
            position: 'fixed',
            height: '100vh',
            overflowY: 'visible',
          },
        }}
      >
        <div className={classes.drawerContent}>
          <HelpAndFeedback open={helpAndFeedbackModal} onClose={toggleHelpAndFeedbackModal} />
          <List className={classes.list}>
            {getAllCategories().map((category, index) => renderCategory(category, '', index))}
          </List>
        </div>
      </Drawer>
      <Box
        sx={{
          width: isMobile ? 0 : state.leftDrawer ? expandedDrawerWidth : collapsedDrawerWidth,
          minWidth: isMobile ? 0 : state.leftDrawer ? expandedDrawerWidth : collapsedDrawerWidth,
          flexShrink: 0,
        }}
      />
    </DndProvider>
  );
};

export default connect({ styles, dispatchers })(LeftDrawerItems);
